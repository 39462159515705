import React from "react";
import './style.scss';

class Arrow extends React.Component {
  render() {
    return (
      <svg 
        className="link-arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="25.492"
        height="21.62"
        viewBox="0 0 25.492 21.62"
      >
        <g
          fill="none"
          stroke="#f03c69"
          strokeWidth="2"
          transform="translate(0 .707)"
        >
          <path d="M13.975 0l10.1 10.1-10.1 10.1"></path>
          <path
            d="M24 0L0 0"
            data-name="Line 1"
            transform="translate(0 10.047)"
          ></path>
        </g>
      </svg>
    );
  }
}

export default Arrow;
