import React from 'react';
import {Link} from 'react-router-dom';
import Layout from '../../Layout/Layout';
import MediaQuery from 'react-responsive';
import Sticky from 'react-stickynode';

import VideoBg from '../../components/VideoBg/VideoBg';
import Arrow from '../../components/Arrow/Arrow';
import Carousel from '../../components/Carousel/Carousel';
import "./style.scss";


import ywcaLogoTop from "../../assets_global/images/header-ywca-wall-women-logo.png";
import wallForWomenLogo from "../../assets_global/images/wall-for-women-logo.png";
import bounceArrow from "../../assets_global/images/header-arrow.png";
import photo1 from "../../assets_global/images/photo-1.jpg";
import photo1dt from "../../assets_global/images/photo-1-dt.jpg";
import photo2 from "../../assets_global/images/photo-2.jpg";
import photo4 from "../../assets_global/images/photo-4.jpg";

import sponsor1 from "../../assets_global/images/sponsor-curiobot.jpg";
import sponsor2 from "../../assets_global/images/sponsor-dtvan.jpg";
import sponsor3 from "../../assets_global/images/sponsor-ea.jpg";
import sponsor4 from "../../assets_global/images/sponsor-eastside.jpg";
import sponsor5 from "../../assets_global/images/sponsor-gasket.jpg";
import sponsor6 from "../../assets_global/images/sponsor-gf.jpg";
import sponsor7 from "../../assets_global/images/sponsor-hinterland.jpg";
import sponsor8 from "../../assets_global/images/sponsor-lbc.jpg";
import sponsor9 from "../../assets_global/images/sponsor-smoking.jpg";
import sponsor10 from "../../assets_global/images/sponsor-united.jpg";


import ywcaLogoFooter from "../../assets_global/images/footer-ywca-logo.png";


class Home extends React.Component {

render() {
  return (
    <>
      <Layout pageCurrent="home">
        <div className="desktop-header">
          <div className="header-video">
          {/* show video bg on desktop */ }
          <MediaQuery minWidth={900}>
            <VideoBg />
          </MediaQuery>
          </div>
          <div className="header-title-content">
            <div className="vertical-center">
              <a href="https://ywcavan.org/" target="_blank" rel="noreferrer"><img className="top-logo animate__animated animate__fadeIn animate__delay-3s" src={ywcaLogoTop} alt="ywca logo" /></a>
              <div className="wall-logo animate__animated animate__fadeInUp animate__delay-1s"><img src={wallForWomenLogo} alt="wall for women logo"/></div>
              <p className="animate__animated animate__fadeIn animate__delay-2s">To break down the walls of violence,<br /> we painted a wall of hope.</p>
              <div className="bounce-wrapper animate__animated animate__fadeIn animate__delay-3s">
                <img className="bounce-arrow animate__animated animate__infinite animate__bounce" src={bounceArrow} alt="bouncing arrow" />
              </div>
            </div>
            {/* show video bg on mobile */ }
            <MediaQuery maxWidth={900}>
              <VideoBg />
            </MediaQuery>

             
           
          </div>

          <MediaQuery minWidth={901}>
              <Sticky enabled={true} top={90} >
              <div className="nav-bar-wrapper">
                <div className="nav-bar animate__animated animate__fadeIn animate__delay-3s">
                  <span className="nav-bar-mobile-logo"> <a href="https://ywcavan.org" target="_blank" rel="noreferrer"><img src={ywcaLogoFooter} alt="ywca logo" /></a></span>

                  <span className="nav-bar-tagline">Support women experiencing violence</span>
                  <span className="donate-now-link"><a href="https://ywcavan.org/wall-for-women-donate" target="_blank" rel="noreferrer">Donate Now</a></span>
                </div>
              </div>
              </Sticky>
            </MediaQuery>
            <MediaQuery maxWidth={900}>
              <Sticky enabled={true} top={80} >
              <div className="nav-bar-wrapper">
             
                <div className="nav-bar animate__animated animate__fadeIn animate__delay-3s">
                <span className="nav-bar-mobile-logo"> <a href="https://ywcavan.org" target="_blank" rel="noreferrer"><img src={ywcaLogoFooter} alt="ywca logo" /></a></span>

                  <span className="nav-bar-tagline">Support women experiencing violence</span>
                  <span className="donate-now-link"><a href="https://ywcavan.org/wall-for-women-donate" target="_blank" rel="noreferrer">Donate Now</a></span>
                </div>
                
                </div>
              </Sticky>
            </MediaQuery>

        </div>
        
        <div className="mobile-header">

        </div>

        <div className="section-1">
          <div className="copy-container">
            <div className="copy">
              <h2>Breaking down <br />the wall</h2>
              <p>The Wall for Women mural is a message of hope that helps paint a picture about how domestic violence hides in plain sight. Hidden within the mural are five smartphone camera-activated statistics that give anyone who takes a picture the chance to donate to new housing for women who have experienced&nbsp;violence.</p>

              <p>The mural is painted by Vancouver artist Ola Volo. Inspired by folk art from her Eastern European background, the mural depicts a queen who reclaims her power after leaving an abusive&nbsp;situation.</p>
              <hr className="line" />
              <p className="link-out"><a href="https://ywcavan.org/programs/violence-prevention/crisis-resources-survivors-violence" target="_blank" rel="noreferrer">If you are in immediate need of help, please see this list of crisis resources that are available to&nbsp;you <Arrow /></a> </p>

   
            </div>
            <MediaQuery maxWidth={899}>
            <div className="section-1-photo-mobile">
              <div className="image">
                <p className="caption">Ola Volo, Muralist</p>
                <img src={photo2} alt="photo 2" />
              </div>
            </div>
            </MediaQuery>
          </div>
          <div className="image-container">
            <div className="image">
              <p className="caption">The Wall For Women</p>
              <MediaQuery minWidth={900}>
                <img src={photo1dt} alt="photo 1" />
              </MediaQuery>
              <MediaQuery maxWidth={899}>
              <img src={photo1} alt="photo 1" />
              </MediaQuery>
            </div>
          </div>
        
        </div>
        
        <MediaQuery minWidth={900}>
        <div className="section-1-photo">
          <div className="image">
          <p className="caption">Ola Volo, Muralist</p>
            <img src={photo2} alt="photo 2" />
          </div>
        </div>
        </MediaQuery>

        <div className="section-2 carousel">
          <Carousel />
        </div>
      

        <div className="section-3">
          <div className="image-container">
            <div className="image">
              {/* <p className="caption">Lorem Ipsum Dolor Sit</p> */}
              <img src={photo4} alt="photo 4" />
            </div>
          </div>
          <div className="copy-container">
          <div className="copy">
              <h2>How your <br />donations help</h2>
              <p>Your donation will help the YWCA build new housing to serve women who have experienced violence. </p>
              <hr className="line" />
              <p className="link-out"><a href="https://ywcavan.org/programs/violence-prevention" target="_blank" rel="noreferrer">Click here to learn more <Arrow /></a></p>
            </div>
          </div>
        </div>

        <div className="footer">
          <a className="donate-bar-link" href="https://ywcavan.org/wall-for-women-donate" target="_blank" rel="noreferrer">
            <div className="donate-bar">
                <span className="nav-bar-tagline">Support women experiencing violence</span>
                <span className="donate-now-link">Donate Now</span>
            </div>
          </a>
          <div className="thanks">
            <div className="thanks-title">
              Special Thanks
            </div>
            <div className="sponsor-wrapper">
              <div className="sponsor-listing">
                <img src={sponsor1} />
              </div>
              <div className="sponsor-listing">
                <img src={sponsor2} />
              </div>
              <div className="sponsor-listing">
                <img src={sponsor3} />
              </div>
              <div className="sponsor-listing">
                <img src={sponsor4} />
              </div>
              <div className="sponsor-listing">
                <img src={sponsor5} /> 
              </div>
              <div className="sponsor-listing">
                <img src={sponsor6} />
              </div>
              <div className="sponsor-listing">
                <img src={sponsor7} />  
              </div>
              <div className="sponsor-listing">
                <img src={sponsor8} />  
              </div>
              <div className="sponsor-listing">
                <img src={sponsor9} />  
              </div>
              <div className="sponsor-listing">
                <img src={sponsor10} /> 
              </div>
            </div>
            

          </div>

          <div className="bottom-bar">
            <a href="https://ywcavan.org" target="_blank" rel="noreferrer"><img src={ywcaLogoFooter} alt="ywca logo" /></a>
            <p>535 Hornby Street, Vancouver BC V6C 2E8&nbsp;&nbsp;&nbsp;<span>Charitable Registration No:108227943 RR0001</span></p>
          </div>
        </div>
      </Layout>
    </>
  )
}
}

export default Home;