import React from 'react';
import Slider from 'react-slick';
import './style.scss';

import Arrow from '../Arrow/Arrow';

import carousel1 from './i/carousel-1.jpg';
import carousel2 from './i/carousel-2.jpg';
import carousel3 from './i/carousel-3.jpg';
import carousel4 from './i/carousel-4.jpg';
import carousel5 from './i/carousel-5.jpg';


class Carousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide:0
    }


  }


  next = (e) => {
    e.preventDefault();
    this.slider.slickNext();
    this.slider2.slickNext();
  };
  previous = (e) => {
    e.preventDefault();
    this.slider.slickPrev();
    this.slider2.slickPrev();
  };

  componentDidMount() {
    //console.log(this.slider.current)
  }


  render() {

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe:false,
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      afterChange: current => this.setState({ activeSlide2: current })
    };

    var settings2 = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe:false,
      fade:true
     };

    return (
      <>
      <div className="carousel-nav-wrapper">
        <a href="#" className="prev-slide" onClick={this.previous}>
          <Arrow />
        </a>
        <a href="#" className="next-slide" onClick={this.next}>
          <Arrow />
        </a>
      </div>
      <div className="fixed-slide-number">
      {this.state.activeSlide+1}/5
      </div>
      <Slider {...settings} className="carousel1" ref={ c=>(this.slider =c)}>
 
          <div className="slide-container">
            <div className="slide-image">
              <img src={carousel1} alt="slide 1" />
            </div>
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                  &nbsp;
                </div>
                <h3><span className="color-teal">2 in 3 Canadians</span><br /> know a victim of<br />domestic violence</h3>
                <div className="copy">
                  <p>“To me, the code in the pocket symbolizes how domestic violence is often a secret that many keep hidden.” —&nbsp;Ola&nbsp;Volo
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="slide-container">
            <div className="slide-image">
              <img src={carousel2} alt="slide 2" />
            </div>
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                &nbsp;
                </div>
                <h3><span className="color-teal">6,000 women in<br />Canada</span> flee <br />violence every day</h3>
                <div className="copy">
                  <p>“I want the code in the queen’s shoe to let women know that if you have to leave an unsafe situation, there are organizations like the YWCA that can support you.” —&nbsp;Ola&nbsp;Volo 
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="slide-container">
            <div className="slide-image">
              <img src={carousel3} alt="slide 3" />
            </div>
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                &nbsp;
                </div>
                <h3><span className="color-teal">1 in 3 women</span> have<br />experienced<br />domestic violence</h3>
                <div className="copy">
                  <p>“It was natural to represent this statistic through three flowers. I wanted to show how 1 in 3 women are held back and can’t bloom into their fullest selves.” —&nbsp;Ola&nbsp;Volo 
                  </p>
                </div>
              </div>
            </div>     
          </div>
          
          <div className="slide-container">
            <div className="slide-image">
              <img src={carousel4} alt="slide 4" />
            </div>
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                &nbsp;
                </div>
                <h3>Domestic violence<br />has <span className="color-teal">increased up to 30% during COVID-19</span></h3>
                <div className="copy">
                  <p>“Domestic violence has risen dramatically during COVID. And, in the mural, she is rising to take a stand against it.” —&nbsp;Ola&nbsp;Volo 
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="slide-container">
            <div className="slide-image">
              <img src={carousel5} alt="slide 5" />
            </div>
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                &nbsp;
                </div>
                <h3>A woman is killed by a partner<span className="color-teal"> every<br />6 days</span></h3>
                <div className="copy">
                  <p>“Snakes can have a duel expression of good and evil. In the mural, the snake represents an abusive partner who changes between charm and harm.” —&nbsp;Ola&nbsp;Volo
                  </p>
                </div>
              </div>
            </div>
          </div>
 
        </Slider>








        <Slider {...settings2} className="carousel2" ref={ c=>(this.slider2 =c)}>
        
          <div className="slide-container">
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                  1/5
                </div>
                <h3><span className="color-teal">2 in 3 Canadians</span><br /> know a victim of<br />domestic violence</h3>
                <div className="copy">
                  <p>“To me, the code in the pocket symbolizes how domestic violence is often a secret that many keep hidden.” —&nbsp;Ola&nbsp;Volo
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="slide-container">
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                    2/5
                </div>
                <h3><span className="color-teal">6,000 women in<br />Canada</span> flee <br />violence every&nbsp;day</h3>
                <div className="copy">
                  <p>“I want the code in the queen’s shoe to let women know that if you have to leave an unsafe situation, there are organizations like the YWCA that can support you.” —&nbsp;Ola&nbsp;Volo 
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <div className="slide-container">
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                    3/5
                </div>
                <h3><span className="color-teal">1 in 3 women</span> have<br />experienced<br />domestic violence</h3>
                <div className="copy">
                  <p>“It was natural to represent this statistic through three flowers. I wanted to show how 1 in 3 women are held back and can’t bloom into their fullest selves.” —&nbsp;Ola&nbsp;Volo 
                  </p>
                </div>
              </div>
            </div>     
          </div>
          
          <div className="slide-container">
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                    4/5
                </div>
                <h3>Domestic violence<br />has <span className="color-teal">increased up to 30% during COVID-19</span></h3>
                <div className="copy">
                  <p>“Domestic violence has risen dramatically during COVID. And, in the mural, she is rising to take a stand against it.” —&nbsp;Ola&nbsp;Volo 
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="slide-container">
            <div className="slide-copy-container">
              <div className="slide-copy">
                <div className="slide-number">
                    5/5
                </div>
                <h3>A woman is killed<br /> by a partner<span className="color-teal"> every<br />6 days</span></h3>
                <div className="copy">
                  <p>“Snakes can have a duel expression of good and evil. In the mural, the snake represents an abusive partner who changes between charm and harm.” —&nbsp;Ola&nbsp;Volo
                  </p>
                </div>
              </div>
            </div>
          </div>

        </Slider>
      </>
    );
  }
}

export default Carousel;