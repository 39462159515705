import React from 'react';

import './style.scss';

import videoBg from '../../assets_global/videos/muralpaint.mp4';
import videoPoster from '../../assets_global/images/mural-paint-backup.jpg';

class VideoBg extends React.Component {

  render() {
    return (
      <>

        <div className="video-bg">
        <div className="video-overlay"></div>
        <video className="top-video" playsInline loop autoPlay muted poster={videoPoster}>
            <source src={videoBg} type="video/mp4" />
          </video>
        
         
        </div>
      
        <div className="callout-inner">
  
        </div>
      </>
    );
  }
}

export default VideoBg;